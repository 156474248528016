import React from 'react';
import PropTypes from 'prop-types';

export const Card = ({ children, disabled, theme, header }) => {
  let cardCss = 'flex flex-col p-4 sm:p-8 rounded-md';

  if (theme === 'transparent') {
    cardCss += ' border border-grey-2 border-dashed bg-transparent shadow-none';
  } else {
    cardCss += ' bg-white shadow-md';
  }

  return (
    <div className={cardCss} data-testid="card">
      <h2
        className={`text-2xl font-bold ${disabled ? 'opacity-50' : ''}`}
        data-testid="card-header"
      >
        {header}
      </h2>
      <div
        className={`mt-6 ${disabled ? 'opacity-50' : ''}`}
        data-testid="card-body"
      >
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  header: PropTypes.any,
};

import React from 'react';

export const RadioGroup = (props) => (
  <div className="mt-5" {...props.test}>
    {props.options &&
      props.options.map((option) => (
        <div key={option.value} className="inline-block relative">
          <label
            className="cursor-pointer flex h-6 pr-8 text-lg"
            htmlFor={option.value}
            data-testid={`radio-group-${option.value}`}
          >
            <input
              className="hidden"
              type="radio"
              name={props.name}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              id={option.value}
              value={option.value}
              checked={option.value === props.checked}
            />
            <span className="cursor-pointer h-6 w-6 mr-3 rounded-full flex items-center justify-center border border-solid border-grey-2">
              {option.value === props.checked && (
                <span className="absolute cursor-pointer items-center h-4 w-4 rounded-full bg-secondary"></span>
              )}
            </span>
            {option.label}
          </label>
        </div>
      ))}
  </div>
);

import React from 'react';
import PropTypes from 'prop-types';

import Angle from '../../../../../images/svg/angle.svg';
import Check from '../../../../../images/svg/check.svg';

export const AccordionItemHeader = ({ title, isCollapsed, isDone }) => {
  const disabled = isCollapsed && !isDone;

  return (
    <h2
      className={`text-2xl font-bold flex items-center ${
        disabled ? 'border-b border-grey-1 pb-8 opacity-50' : ''
      }`}
    >
      {title}
      {isDone ? (
        <Check className="w-6 h-6 ml-2 fill-green" />
      ) : (
        <Angle
          className={`w-5 h-3 ml-3 ${disabled ? 'rotate-180 opacity-50' : ''}`}
        />
      )}
    </h2>
  );
};

AccordionItemHeader.prototypes = {
  title: PropTypes.string,
  isCollapsed: PropTypes.bool,
};

import React, { useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import { object } from 'yup';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import Api from '../../../../utilities/api';
import { FormGroup } from '../../../shared/form/FormGroup';
import { FormSex } from '../../../shared/form/FormSex';
import { FormBirthYear } from '../../../shared/form/FormBirthYear';
import { FormMaritalStatus } from '../../../shared/form/FormMaritalStatus';
import { Button } from '../../../shared/Button';
import { onboardingStep } from '../../../../state/onboardingStep';
import {
  personalInfo,
  getUserPersonalInfo,
  getSocDemDataId,
} from '../../../../state/personalInfo';
import { ErrorContext } from '../../../../contexts/ErrorContext';
import { gender, birthYear, maritalStatus } from '../../../../validations';
import { useGTMEvent } from '../../../../hooks/useGTMEvent';

const ValidationSchema = object().shape({
  gender,
  birthYear,
  maritalStatus,
});

export const User = ({ isCollapsed }) => {
  const [localData, setLocalData] = useState({
    gender: '',
    birthYear: '',
    maritalStatus: '',
  });
  const { setGlobalError } = useContext(ErrorContext);
  const setUserValues = useSetRecoilState(personalInfo);
  const userValues = useRecoilValue(getUserPersonalInfo);
  const id = useRecoilValue(getSocDemDataId);

  const setStep = useSetRecoilState(onboardingStep);
  const pushGTMEvent = useGTMEvent({
    event: 'dashboard:info:compsed1',
    eventCategory: 'dashboard',
    eventAction: 'click',
    eventLabel: 'dashboard_info_compsed1',
  });

  function onChange(event, handleChange, name) {
    handleChange(event);
    setLocalData((oldState) => ({
      ...oldState,
      [name]: event.target.value,
    }));
  }

  async function handleSubmit(values) {
    try {
      pushGTMEvent();
      setUserValues((oldState) => ({
        ...oldState,
        ...localData,
      }));

      await Api.saveSocdemData(values, id);

      setStep('Financial');
    } catch (error) {
      setGlobalError(error);
    }
  }

  const isInitialValid = ValidationSchema.isValidSync(userValues);

  return (
    !isCollapsed && (
      <Formik
        initialValues={userValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
        isInitialValid={isInitialValid}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form>
            <FormGroup>
              <FormSex
                onChange={(event) => onChange(event, handleChange, 'gender')}
                onBlur={handleBlur}
                checked={values?.gender}
              />
            </FormGroup>
            <FormGroup>
              <FormBirthYear
                onChange={(event) => onChange(event, handleChange, 'birthYear')}
                touched={touched}
                errors={errors}
              />
            </FormGroup>
            <FormGroup>
              <FormMaritalStatus
                onChange={(event) =>
                  onChange(event, handleChange, 'maritalStatus')
                }
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                selectedMaritalStatus={values?.maritalStatus}
              />
            </FormGroup>
            <FormGroup className="text-center">
              <Button
                type="submit"
                onClick={handleSubmit}
                border="normal"
                className="w-2/5 mt-12"
                disabled={isSubmitting || !dirty || !isValid}
                data-testid="personal-info-user-next-button"
              >
                Pokračovat
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    )
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBarStep } from './ProgressBarStep';

export const ProgressBar = ({ steps, top }) => {
  return (
    <section
      className={`h-full flex flex-col items-center ${
        top ? 'pt-32' : 'justify-center'
      }`}
    >
      {steps.map((step) => (
        <ProgressBarStep key={step.key} {...step} />
      ))}
    </section>
  );
};

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
};

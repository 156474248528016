import React from 'react';
import PropTypes from 'prop-types';

export const ProgressBar = ({ className = '' }) => {
  return (
    <div className={`flex flex-col w-6 space-y-1 ${className}`}>
      <div className="bg-grey-2 h-1 rounded-sm"></div>
      <div className="bg-grey-2 h-1 rounded-sm"></div>
      <div className="bg-grey-2 h-1 rounded-sm"></div>
      <div className="bg-grey-2 h-1 rounded-sm"></div>
      <div className="bg-green h-1 rounded-sm"></div>
      <div className="bg-green h-1 rounded-sm"></div>
      <div className="bg-green h-1 rounded-sm"></div>
    </div>
  );
};

ProgressBar.propTypes = {
  className: PropTypes.string,
};

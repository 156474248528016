import React, { useState, useContext } from 'react';
import useSWR from 'swr';
import format from 'date-fns/format';
import { cs } from 'date-fns/locale';

import arrowRight from '../../../../../images/icons/arrow-right.svg';
import Check from '../../../../../images/svg/check.svg';
import Clock from '../../../../../images/svg/clock.svg';
import ArrowRightCircle from '../../../../../images/svg/arrow-right-circle.svg';
import ArrowRight from '../../../../../images/svg/arrow-right.svg';

import { AuthContext } from '../../../../../contexts/AuthContext';
import { ErrorContext } from '../../../../../contexts/ErrorContext';
import { Card } from '../../../../../components/shared/Card';
import { Button } from '../../../../../components/shared/Button';
import { ButtonContentWithIcon } from '../../../../../components/shared/ButtonContentWithIcon';
import api from '../../../../../utilities/api';
import { humanizePersonalNo } from '../../../../../utilities/humanizePersonalNo';
import { useGTMEvent } from '../../../../../hooks/useGTMEvent';

const mapping = {
  'MYLNY ZAP': 'Mylný zápis',
  NEVYRIZENA: 'Nevyřízená věc',
  OBZIVLA: 'Obživlá věc',
  ODSKRTNUTA: 'Odškrtnutá',
  VYRIZENA: 'Vyřízená věc',
  PRAVOMOCNA: 'Pravomocná věc',
  KONKURS: 'Konkurs',
  'ZRUŠENO VS': 'Zrušeno vrchním soudem',
  'K-PO ZRUŠ.': 'Konkurs po zrušení',
  ÚPADEK: 'V úpadku',
  REORGANIZ: 'Reorganizace',
  ODDLUŽENÍ: 'Oddlužení',
  MORATORIUM: 'Moratorium',
  'NEVYR-POST': 'Postoupená věc',
};

const ISIR_NO_RECORD_ERROR_CODE = 'WS_2';

const InsolvencePersonalNumberAbsent = () => {
  const verifyGTMEvent = useGTMEvent({
    event: 'dashboard:insolvency:register',
    eventCategory: 'dashboard',
    eventAction: 'button',
    eventLabel: 'dashboard_insolvency_register',
  });

  return (
    <Card
      theme="transparent"
      header={<span data-testid="insolvence-title">Insolvenční rejstřík</span>}
    >
      <p data-testid="insolvence-text">
        Potřebujete ověřit, zda nejste v insolvenci? Umožníme Vám nahlédnout do
        insolvenčního rejstříku. Rychle a jednoduše.
      </p>

      <div className="mt-4" data-testid="insolvence-link">
        <ButtonContentWithIcon
          onClick={verifyGTMEvent}
          url="/moje/insolvence"
          label="zkontrolovat rejstřík"
          iconSrc={arrowRight}
        />
      </div>
    </Card>
  );
};

const InsolvenceEmptyRegister = ({ onClick, timeCreated }) => {
  const { user } = useContext(AuthContext);

  const { personalNo } = user;
  return (
    <Card
      header={
        <div className="flex justify-between items-center">
          <span data-testid="insolvence-title">Insolvenční rejstřík</span>
          <div
            className="flex items-center text-gray text-base font-normal"
            data-testid="insolvence-timestamp"
          >
            <Clock className="inline-block mr-2 w-4 fill-grey-2" />
            {format(new Date(timeCreated), 'do MMMM yyyy', {
              locale: cs,
            })}
          </div>
        </div>
      }
    >
      <RefreshButton onClick={onClick} />
      <p className="flex items-center text-lg" data-testid="insolvence-text">
        <Check className="inline-block mr-3 w-6 fill-green" />K rodnému číslu{' '}
        {humanizePersonalNo(personalNo)} nebyl nalezen žádný záznam.
      </p>
    </Card>
  );
};

const RefreshButton = ({ onClick }) => {
  const [isReloading, setIsReloading] = useState(false);
  const againGTMEvent = useGTMEvent({
    event: 'dashboard:insolvency:register:again',
    eventCategory: 'dashboard',
    eventAction: 'button',
    eventLabel: 'dashboard_insolvency_register_again',
  });

  const handleClick = async () => {
    againGTMEvent();

    setIsReloading(true);
    await onClick();
    setIsReloading(false);
  };
  return (
    <Button
      type="button"
      onClick={handleClick}
      border="normal"
      disabled={isReloading}
      className="my-6"
      data-testid="dashboard-start-insolvence"
    >
      <span className="mr-4 uppercase">Zkontrolovat znovu</span>
      <ArrowRight className="w-6 h-6 inline" />
    </Button>
  );
};

export const Insolvence = () => {
  const { user } = useContext(AuthContext);
  const { setGlobalError } = useContext(ErrorContext);

  const { personalNo } = user;

  const { data, mutate } = useSWR(personalNo ? `/api/isir-register` : null, {
    suspense: true,
    revalidateOnFocus: false,
  });

  const refresh = async () => {
    try {
      const response = await api.loadInsolvence();
      await mutate(response, false);
    } catch (error) {
      setGlobalError(error);
    }
  };

  if (!personalNo) {
    return <InsolvencePersonalNumberAbsent />;
  }

  if (data.status === 204) {
    return (
      <InsolvenceEmptyRegister onClick={refresh} timeCreated={new Date()} />
    );
  }

  const {
    stav: { pocetVysledku, kodChyby },
    data: dataArray,
    timeCreated,
  } = data;

  if (
    kodChyby === ISIR_NO_RECORD_ERROR_CODE ||
    pocetVysledku === 0 ||
    pocetVysledku === null
  ) {
    return (
      <InsolvenceEmptyRegister onClick={refresh} timeCreated={timeCreated} />
    );
  }

  return (
    <Card
      header={
        <div className="flex justify-between items-center">
          <span data-testid="insolvence-title">Insolvenční rejstřík</span>
          <div
            className="flex items-center text-gray text-base font-normal"
            data-testid="insolvence-timestamp"
          >
            <Clock className="inline-block mr-2 w-4 fill-grey-2" />
            {format(new Date(timeCreated), 'do MMMM yyyy', {
              locale: cs,
            })}
          </div>
        </div>
      }
    >
      <RefreshButton onClick={refresh} />
      <table className="table-auto mt-6">
        <thead className="text-left uppercase">
          <tr>
            <th className="px-2 py-1 md:px-4 md:py-2">Stav</th>
            <th className="px-2 py-1 md:px-4 md:py-2">Spisová značka</th>
            <th className="px-2 py-1 md:px-4 md:py-2">Rodné číslo</th>
            <th className="px-2 py-1 md:px-4 md:py-2">Bydlište</th>
            <th></th>
          </tr>
        </thead>
        <tbody
          className="ring-1 ring-black/5 rounded-md"
          data-testid="insolvence-entries"
        >
          {dataArray.map(
            ({
              bcVec,
              cisloSenatu,
              druhVec,
              rocnik,
              rc,
              druhStavKonkursu,
              ulice,
              cisloPopisne,
              mesto,
              psc,
            }) => (
              <tr key={bcVec}>
                <td
                  className="px-2 py-1 md:px-4 md:py-2"
                  data-testid="insolvence-table-type"
                >
                  {mapping[druhStavKonkursu] || druhStavKonkursu}
                </td>
                <td
                  className="px-2 py-1 md:px-4 md:py-2"
                  data-testid="insolvence-table-identification"
                >
                  {cisloSenatu} {druhVec} {bcVec}/{rocnik}
                </td>
                <td
                  className="px-2 py-1 md:px-4 md:py-2"
                  data-testid="insolvence-table-personal-no"
                >
                  {rc}
                </td>
                <td
                  className="px-2 py-1 md:px-4 md:py-2"
                  data-testid="insolvence-table-address"
                >
                  {ulice} {cisloPopisne}, {mesto} {psc}
                </td>
                <td
                  className="px-2 py-1 md:px-4 md:py-2"
                  data-testid="insolvence-table-isir-link"
                >
                  <a
                    href={`https://isir.justice.cz/isir/ueu/vysledek_lustrace.do?rc=${rc.replace(
                      '/',
                      ''
                    )}&aktualnost=AKTUALNI_I_UKONCENA`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ArrowRightCircle className="w-6" />
                  </a>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </Card>
  );
};

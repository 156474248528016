import { atom, selector } from 'recoil';
import Api from '../utilities/api';
import { replaceNulls } from '../utilities/replaceNulls';

export const personalInfo = atom({
  key: 'personalInfo',
  default: {
    id: '',
    birthYear: '',
    education: '',
    housingType: '',
    gender: '',
    maritalStatus: '',
    zipCode: '',
    primaryIncome: '',
  },
});

export const getSocDemDataId = selector({
  key: 'getSocDemDataId',
  get: ({ get }) => {
    const { id } = get(personalInfo);

    return id;
  },
});

export const isUserPersonalInfoFinished = selector({
  key: 'isUserPersonalInfoFinished',
  get: ({ get }) => {
    const { birthYear, maritalStatus, gender } = get(personalInfo);

    return [birthYear, maritalStatus, gender].every(Boolean);
  },
});

export const getUserPersonalInfo = selector({
  key: 'getUserPersonalInfo',
  get: ({ get }) => {
    const { birthYear, maritalStatus, gender } = get(personalInfo);

    return { birthYear, maritalStatus, gender };
  },
});

export const isFinancialPersonalInfoFinished = selector({
  key: 'isFinancialPersonalInfoFinished',
  get: ({ get }) => {
    const { education, primaryIncome } = get(personalInfo);

    return [education, primaryIncome].every(Boolean);
  },
});

export const getFinancialPersonalInfo = selector({
  key: 'getFinancialPersonalInfo',
  get: ({ get }) => {
    const { education, primaryIncome } = get(personalInfo);

    return { education, primaryIncome };
  },
});

export const isHouseholdPersonalInfoFinished = selector({
  key: 'isHouseholdPersonalInfoFinished',
  get: ({ get }) => {
    const { zipCode, housingType } = get(personalInfo);

    return [zipCode, housingType].every(Boolean);
  },
});

export const getHouseholdPersonalInfo = selector({
  key: 'getHouseholdPersonalInfo',
  get: ({ get }) => {
    const { zipCode, housingType } = get(personalInfo);

    return { zipCode, housingType };
  },
});

export const getOpenApplication = selector({
  key: 'getOpenApplication',
  get: async () => {
    const applications = await Api.loadApplications();
    const openedApplication = applications.filter(
      (app) => app.status === 'OPEN'
    )[0];

    if (openedApplication) {
      return openedApplication;
    }

    return await Api.createApplication();
  },
});

export const getPersonalInfoData = selector({
  key: 'getPersonalInfoData',
  get: async ({ get }) => {
    const openedApplication = await get(getOpenApplication);

    if (
      openedApplication.socdemDataId === null ||
      openedApplication.socdemDataId === undefined
    ) {
      const data = await Api.createSocdemData(openedApplication.id);
      return replaceNulls(data);
    }

    const data = await Api.loadSocdemData(openedApplication.socdemDataId);
    return replaceNulls(data);
  },
});

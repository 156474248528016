import React from 'react';
import loading from '../../../images/loading.svg';

export const Spinner = ({ size, className = '' }) => {
  return (
    <div className="flex" data-testid="spinner">
      <img
        alt="spinner"
        width={size}
        height={size}
        src={loading}
        className={`animate-spin ${className}`}
        data-testid="spinner-img"
      />
    </div>
  );
};

import React from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { Router, Redirect } from '@reach/router';

import { Dashboard } from '../../components/moje/Dashboard';
import { Score } from '../../components/moje/Score';
import { Insolvence } from '../../components/moje/Insolvence';
import AuthenticatedRoute from '../../components/AuthenticatedRoute';

const Moje = () => {
  const [redirect] = useQueryParam('redirect', StringParam);

  return (
    <Router basepath="/moje">
      <AuthenticatedRoute path="/nastenka" component={Dashboard} />
      <AuthenticatedRoute path="/skore/*" component={Score} />
      <AuthenticatedRoute path="/insolvence/*" component={Insolvence} />
      {redirect && <Redirect to={`/moje/${redirect}`} from="/" />}
      <Redirect to="/ups/404" from="/*" />
    </Router>
  );
};

export default Moje;

import React, { useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import { object } from 'yup';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import Api from '../../../../utilities/api';
import { FormGroup } from '../../../shared/form/FormGroup';
import { FormEducation } from '../../../shared/form/FormEducation';
import { FormPrimaryIncome } from '../../../shared/form/FormPrimaryIncome';
import { Button } from '../../../shared/Button';
import { onboardingStep } from '../../../../state/onboardingStep';
import {
  personalInfo,
  getFinancialPersonalInfo,
  getSocDemDataId,
} from '../../../../state/personalInfo';
import { ErrorContext } from '../../../../contexts/ErrorContext';
import { primaryIncome, education } from '../../../../validations';
import { useGTMEvent } from '../../../../hooks/useGTMEvent';

const ValidationSchema = object().shape({ primaryIncome, education });

export const Financial = ({ isCollapsed }) => {
  const [localData, setLocalData] = useState({
    primaryIncome: '',
    education: '',
  });
  const { setGlobalError } = useContext(ErrorContext);
  const setFinancialValues = useSetRecoilState(personalInfo);
  const financialValues = useRecoilValue(getFinancialPersonalInfo);
  const id = useRecoilValue(getSocDemDataId);

  const setStep = useSetRecoilState(onboardingStep);
  const pushGTMEvent = useGTMEvent({
    event: 'dashboard:info:compsed2',
    eventCategory: 'dashboard',
    eventAction: 'click',
    eventLabel: 'dashboard_info_compsed2',
  });

  function onChange(event, handleChange, name) {
    handleChange(event);
    setLocalData((oldState) => ({
      ...oldState,
      [name]: event.target.value,
    }));
  }

  async function handleSubmit(values) {
    try {
      pushGTMEvent();
      setFinancialValues((oldState) => ({
        ...oldState,
        ...localData,
      }));

      await Api.saveSocdemData(values, id);

      setStep('Household');
    } catch (error) {
      setGlobalError(error);
    }
  }

  const isInitialValid = ValidationSchema.isValidSync(financialValues);

  return (
    !isCollapsed && (
      <Formik
        initialValues={financialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
        isInitialValid={isInitialValid}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form>
            <FormGroup>
              <FormPrimaryIncome
                onChange={(event) =>
                  onChange(event, handleChange, 'primaryIncome')
                }
                touched={touched}
                errors={errors}
                autofocus
              />
            </FormGroup>
            <FormGroup>
              <FormEducation
                onChange={(event) => onChange(event, handleChange, 'education')}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                selectedEducation={values.education}
              />
            </FormGroup>
            <FormGroup className="text-center">
              <Button
                type="submit"
                onClick={handleSubmit}
                border="normal"
                className="w-2/5 mt-12"
                disabled={isSubmitting || !dirty || !isValid}
                data-testid="personal-info-financial-next-button"
              >
                Pokračovat
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    )
  );
};

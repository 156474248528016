import React, { useEffect } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import pdfDocument from '../../../../assets/documents/zasady-ochrany-osobnich-udaju.pdf';
import { User } from './User';
import { Financial } from './Financial';
import { Household } from './Household';
import { handleOnboardingStep } from '../../../../state/onboardingStep';
import { Accordion, AccordionItem } from '../../../shared/Accordion';
import { Page } from '../../../shared/Page';
import { AccordionItemHeader } from './Accordion/AccordionItemHeader';
import {
  personalInfo,
  getPersonalInfoData,
  isUserPersonalInfoFinished,
  isFinancialPersonalInfoFinished,
  isHouseholdPersonalInfoFinished,
} from '../../../../state/personalInfo';

export const PersonalInfo = () => {
  const [step] = useRecoilState(handleOnboardingStep);
  const isUserFinished = useRecoilValue(isUserPersonalInfoFinished);
  const isFinancialFinished = useRecoilValue(isFinancialPersonalInfoFinished);
  const isHouseholdFinished = useRecoilValue(isHouseholdPersonalInfoFinished);
  const loadedSocdemData = useRecoilValue(getPersonalInfoData);
  const setSocdemData = useSetRecoilState(personalInfo);

  useEffect(() => {
    setSocdemData((prevState) => ({
      ...prevState,
      ...loadedSocdemData,
    }));
  }, [loadedSocdemData, setSocdemData]);

  return (
    <Page
      title="Sdělte nám o sobě více"
      description={`Abychom mohli co nejpřesněji spočítat, jaké je Vaše skóre, potřebujeme od Vás pár informací. Vaše osobní údaje jsou u nás v bezpečí. Více naleznete&nbsp;v <a href=${pdfDocument} class='underline whitespace-nowrap' target="_blank" rel="noreferrer">Zásadách ochrany osobních údajů</a>.`}
    >
      <Accordion expandedItem={step} className="block bg-green">
        <AccordionItem
          className="pt-8 max-w-5xl mx-auto"
          index="User"
          headerComponent={
            <AccordionItemHeader isDone={isUserFinished} title="Osobní údaje" />
          }
        >
          <User />
        </AccordionItem>
        <AccordionItem
          className="pt-8 max-w-5xl mx-auto"
          index="Financial"
          headerComponent={
            <AccordionItemHeader
              isDone={isFinancialFinished}
              title="Finanční situace a vzdělání"
            />
          }
        >
          <Financial />
        </AccordionItem>
        <AccordionItem
          className="pt-8 max-w-5xl mx-auto"
          index="Household"
          headerComponent={
            <AccordionItemHeader
              isDone={isHouseholdFinished}
              title="Typ bydlení"
            />
          }
        >
          <Household />
        </AccordionItem>
      </Accordion>
    </Page>
  );
};

import React, { useContext, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { object } from 'yup';
import { navigate } from 'gatsby';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Api from '../../../../utilities/api';
import { FormGroup } from '../../../shared/form/FormGroup';
import { FormZipCode } from '../../../shared/form/FormZipCode';
import { FormHousingType } from '../../../shared/form/FormHousingType';
import { Button } from '../../../shared/Button';
import { ErrorContext } from '../../../../contexts/ErrorContext';
import {
  personalInfo,
  getHouseholdPersonalInfo,
  isHouseholdPersonalInfoFinished,
  getSocDemDataId,
} from '../../../../state/personalInfo';
import { zipCode, housingType } from '../../../../validations';
import { useGTMEvent } from '../../../../hooks/useGTMEvent';

const ValidationSchema = object().shape({ zipCode, housingType });

export const Household = ({ isCollapsed }) => {
  const [localData, setLocalData] = useState({
    zipCode: '',
    housingType: '',
  });
  const { setGlobalError } = useContext(ErrorContext);
  const setHouseholdValues = useSetRecoilState(personalInfo);
  const householdValues = useRecoilValue(getHouseholdPersonalInfo);
  const id = useRecoilValue(getSocDemDataId);
  const isHouseholdFinished = useRecoilValue(isHouseholdPersonalInfoFinished);
  const pushGTMEvent = useGTMEvent({
    event: 'dashboard:info:compsed3',
    eventCategory: 'dashboard',
    eventAction: 'click',
    eventLabel: 'dashboard_info_compsed3',
  });

  useEffect(() => {
    if (isHouseholdFinished) {
      return navigate('/moje/skore/bankovni-vypisy');
    }
  }, [isHouseholdFinished]);

  function onChange(event, handleChange, name) {
    handleChange(event);
    setLocalData((oldState) => ({
      ...oldState,
      [name]: event.target.value,
    }));
  }

  async function handleSubmit(values) {
    try {
      pushGTMEvent();
      setHouseholdValues((oldState) => ({
        ...oldState,
        ...localData,
      }));

      await Api.saveSocdemData(values, id);

      navigate('/moje/skore/bankovni-vypisy');
    } catch (error) {
      setGlobalError(error);
    }
  }

  const isInitialValid = ValidationSchema.isValidSync(householdValues);

  return (
    !isCollapsed && (
      <Formik
        initialValues={householdValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
        isInitialValid={isInitialValid}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form>
            <FormGroup>
              <FormZipCode
                onChange={(event) => onChange(event, handleChange, 'zipCode')}
                touched={touched}
                errors={errors}
                autofocus
              />
            </FormGroup>
            <FormGroup>
              <FormHousingType
                onChange={(event) =>
                  onChange(event, handleChange, 'housingType')
                }
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                selectedHousingType={values.housingType}
              />
            </FormGroup>
            <FormGroup className="text-center">
              <Button
                type="submit"
                onClick={handleSubmit}
                border="normal"
                className="w-2/5 mt-12"
                disabled={isSubmitting || !dirty || !isValid}
                data-testid="personal-info-household-next-button"
              >
                Pokračovat
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    )
  );
};

import React from 'react';
import PropTypes from 'prop-types';

import Oval from '../../../../images/svg/oval.svg';
import OvalActive from '../../../../images/svg/oval-active.svg';

export const ProgressBarStep = ({ state, label }) => {
  return (
    <div className="flex flex-row justify-between items-center my-4 w-2/3">
      <div className="w-12 flex shrink-0 justify-center items-center">
        {state !== 'in-progress' && (
          <Oval
            className={`w-6 ${
              state === 'pending' ? 'fill-grey-3 opacity-25' : ''
            } ${state === 'done' ? 'fill-primary' : ''}`}
          />
        )}
        {state === 'in-progress' && (
          <OvalActive className="w-12 fill-primary" />
        )}
      </div>
      <div
        className={`flex flex-1 ${state === 'in-progress' ? 'font-bold' : ''}`}
      >
        <span className="pl-8">{label}</span>
      </div>
    </div>
  );
};

ProgressBarStep.propTypes = {
  state: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

import React from 'react';

export const Chart = ({ className }) => {
  return (
    <div className={`flex items-end space-x-1 ${className}`}>
      <div className="bg-grey-2 w-3 h-4"></div>
      <div className="bg-grey-2 w-3 h-8"></div>
      <div className="bg-green w-3 h-12"></div>
    </div>
  );
};
